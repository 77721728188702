import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Creating a Ad Manager test enviroment</h1>
    <p>This minimal python recipe will tell you how to create a Google Ad Manager test enviroment and add a user with administrator privilges.</p>
    
    <pre class="pre-scrollable">
        <code>
        <p>#PYTHON</p>
        x = 1<br />
            if x == 1:<br />
            # indented four spaces<br />
            print("x is 1.")<br />
        </code>
    </pre>
    
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage